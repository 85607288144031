.main {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 2rem;
}

.font-design { 
  font-style: normal;
  font-weight: 450;
}

.main-color {
  color: #D9D5D2;
}

.secondary-color {
  color: #8C5D58;
}

.heavy-weight {
  font-weight: 800;
}

.tertiary-color {
  color: #BFBCBA;
}

a:hover {
  color: #D9D5D2;
  
}


.main-projects {
  padding-top: 6rem;
}

.big-main-text {
  font-size: 2rem;
  font-weight: 500;
}

.med-main-text {
  font-size: 1.3rem;
  font-weight: 500;
}

.sub-small-main-text { 
  font-size: 1rem;
  font-weight: 400;
}


