.pill {
  width: max-content;
  height: 2.25rem;
  background: #bfbcba;
}

.round {
  border-radius: 2rem;
}

.small-text {
  color: #262626;
  font-size: 1rem;
}

.center {
  display: inline-flex;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  align-items: center;
}