.about-section {
  display: flex;
  flex-direction: column;
  gap: 1vw;
}

.text {
  font-style: normal;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 2.2vw;
  padding-right: 22vw;
  text-align: left;
}
