.icons {
    display: inline-flex;
    gap: 5rem;
}

.contact {
    display: flex;
    justify-content: center;
}

.icon {
    font-size: 2rem;
}