body {
  background-color: #262626;
}

.App {
  display: grid;
  font-family: "Montserrat", sans-serif;
  padding: 5% 13%;
  column-gap: 8vw;
  row-gap: 5vw;
}

.header {
  font-style: normal;
  font-weight: 500;
  font-size: 1.5rem;
  display: flex;
}