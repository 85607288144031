.profile-img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profile-background {
  background-color: #dad7d2;
  width: 100%;
  border-radius: 15px;
  grid-column-start: 2;
}

@media (max-width: 1200px) {
  .profile-background {
    grid-column-start: 1;
  }
}
